<template>
  <div class="layer_03">
    <div class="layer_top">
      <h2 class="layer_top_title">
        {{ this.status == 8 ? "카테고리" : "행정구역" }} 그룹
        {{ id ? "수정" : "등록" }}
      </h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="layer_03_box">
      <form action="#">
        <table class="layer_03_table">
          <colgroup>
            <col style="width: 107px" />
            <col style="width: 535px" />
            <col style="width: 107px" />
            <col style="width: 505px" />
          </colgroup>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pt_20 pb_15">그룹 명</td>
            <td class="pt_20 pb_15">
              <input type="text" class="input_box_05" v-model="name" />
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="big_box_title pb_15">스탬프</td>
            <td colspan="3" class="pb_15">
              <span class="img_btn">
                <base-button class="mb_14" type="upload" @click="callStamp"
                  >찾아보기</base-button
                >
              </span>
              <base-table
                v-if="stamp.ids != ''"
                :useNo="true"
                :totalItems="totalItems"
                :currentPage="currentPage"
                :pageSize="pageSize"
                :loading="false"
                :fields="fields"
                :items="stamp.items"
                :isEdit="false"
                :isDelete="true"
                :isCheckbox="false"
                checkText="필수 스탬프"
                @delete="onDeleteClick"
              />
            </td>
          </tr>
          <tr class="layer_tbl">
            <!-- <td class="layer_tbl_title pb_15">운영기간</td>
            <td class="pb_15">
              <date-picker
                v-model="periodStartDt"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                  />
                </template>
              </date-picker>
              <span class="ml_04 mr_04">~</span>
              <date-picker
                v-model="periodEndDt"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                  />
                </template>
              </date-picker>
            </td> -->
            <td class="layer_tbl_title pb_15">이미지</td>
            <td class="pb_15 standard">
              <input
                type="text"
                class="lookfor_box mr_30"
                v-model="imgName"
                readonly
              />
              <input
                style="display: none"
                type="file"
                accept=".png, .jpg, .jpeg, .bmp, .gif"
                id="file_upload"
                ref="refSelectedImg"
                @change="previewFile"
              />
              <label for="file_upload" class="file_btn"> 찾아보기 </label>
              <div
                v-if="selectedImg || imgName"
                class="img_name_box stamp_img_n_01"
              >
                <span class="img_name">{{ imgName }}</span>
                <div class="cancel_img" @click.prevent.stop="removeFile">
                  <span></span>
                  <span></span>
                </div>
              </div>
              <!-- [D] 이미지파일명 텍스트
										<div class="img_name_box img_n">
												<a href="#none" class="img_name">sub_01.jpg</a>
												<a href="#none" class="cancel_img"></a>
										</div>
												mission_top_img_n 를 넣어줘야함
												--></td>
          </tr>
          <!-- <tr class="layer_tbl">
            <td class="layer_tbl_title">링크</td>
            <td colspan="3">
              <div class="layer_02select_box mr_30">
                <base-select
                  :options="links01"
                  v-model="selectedLink01"
                  placeholder="선택해 주세요."
                />
              </div>
              <div class="layer_02select_box mr_30">
                <base-select
                  :options="links02"
                  v-model="selectedLink02"
                  placeholder="선택해 주세요."
                />
              </div>
              <div class="layer_02select_box">
                <base-select
                  :options="links03"
                  v-model="selectedLink03"
                  placeholder="선택해 주세요."
                />
              </div>
            </td>
          </tr> -->
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="edit">{{
            id ? "수정" : "등록"
          }}</base-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import StampCall from "@/components/StampCall.vue";
import Dialog from "@/components/Dialog.vue";
export default {
  components: {
    BaseButton,
    BaseTable,
  },
  props: {
    id: Number,
    status: Number,
  },
  data() {
    return {
      states: [
        { name: "운영 중", value: "운영 중" },
        { name: "정지", value: "정지" },
      ],
      selectedState: "",
      turns: [
        { name: "1번", value: "1번" },
        { name: "2번", value: "2번" },
        { name: "3번", value: "3번" },
        { name: "4번", value: "4번" },
        { name: "5번", value: "5번" },
      ],
      selectedTurn: "",
      links01: [
        { name: "미션 스탬프", value: "미션 스탬프" },
        { name: "미션 스탬프", value: "미션 스탬프" },
      ],
      selectedLink01: "",
      links02: [
        { name: "완주군 스탬프", value: "완주군 스탬프" },
        { name: "완주군 스탬프", value: "완주군 스탬프" },
      ],
      selectedLink02: "",
      links03: [
        { name: "BTS 스탬프", value: "BTS 스탬프" },
        { name: "BTS 스탬프", value: "BTS 스탬프" },
      ],
      selectedLink03: "",
      name: "",
      imgName: "",
      content: {
        title: "",
        subtitle: "",
        description: "",
        picture: "",
      },
      stamp: {
        items: [],
        ids: [],
      },
      fields: [
        {
          key: "id",
          width: "140px",
          label: "id",
        },
        {
          key: "picture",
          width: "100px",
          label: "스탬프",
          tag: "img",
        },
        {
          key: "name",
          width: "140px",
          label: "스탬프 명",
        },
      ],
      totalItems: 1,
      totalPages: 1,
      currentPage: 1,
      pageSize: 1,
      selectedImg: null,
    };
  },
  created() {
    if (this.id) {
      this.fetch();
    }
  },
  methods: {
    async fetch() {
      await client.oldGroupFindone(this.id).then(
        (response) => {
          const { data } = response;

          this.name = data.name;
          if (data.content.picture.name) {
            this.imgName = data.content.picture.name;
            this.content.picture = data.content.picture.name;
          }

          this.content.title = data.content.title;
          this.content.subtitle = data.content.subtitle;
          this.content.description = data.content.description;
        },
        (error) => {
          const { data } = error.response;
          let message = data.message;
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: message,
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
        }
      );
      if (this.id) {
        let params = {
          page: 1,
          size: 1000,
        };

        await client.oldStampGroupFindone(this.id, params).then(
          (response) => {
            const { data } = response;

            data.items.forEach((item) => {
              if (item.picture.name) {
                item.picture = `${item.picture.url}${item.picture.path}?size=100`;
              }
            });

            this.stamp.items = data.items;
            this.totalItems = this.stamp.items.length;
            this.stamp.items.forEach((item) => {
              this.stamp.ids.push(item.id);
            });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    onDeleteClick(params) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              this.stamp.ids = this.stamp.ids.filter(
                (id) => Number(id) !== Number(params.item.id)
              );
              this.stamp.items = this.stamp.items.filter(
                (item) => Number(item.id) !== Number(params.item.id)
              );
            }
          },
        }
      );
    },
    async fetchStampIds() {
      let params = {
        ids: this.stamp.ids.toString(),
        page: 1,
        size: 1000,
      };

      await client.stampFindAll(params).then(
        (response) => {
          const { data } = response;

          data.items.forEach((item) => {
            // 이미지
            if (item.picture.name) {
              item.picture = `${item.picture.url}${item.picture.path}?size=100`;
            }
          });

          this.stamp.items = data.items;
          this.totalItems = this.stamp.items.length;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async edit() {
      if (this.selectedImg) {
        let form = new FormData();
        form.append("uploadFile", this.selectedImg);
        await client.fileAdd(form).then(
          (response) => {
            this.content.picture = response.data.name;
          },
          (error) => {
            console.log(error);
          }
        );
      } else if (this.imgName) {
        this.content.picture = this.imgName;
      } else {
        this.content.picture = "";
      }

      let stampIds = [];
      this.stamp.ids.forEach((id) => {
        stampIds.push({
          id: id,
        });
      });

      if (!this.name) {
        alert("그룹 명을 입력해주세요");
      } else if (this.stamp.items.length == 0) {
        alert("스탬프를 선택해주세요");
      } else {
        if (this.id) {
          let params = {
            name: this.name,
            content: {
              title: this.name,
              subtitle: this.content.subtitle,
              description: this.content.description,
              picture: this.content.picture,
            },
            stampIds: stampIds,
          };
          await client.oldGroupModify(this.id, params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "정상적으로 수정되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          let params = {
            name: this.name,
            status: this.status,
            content: {
              title: this.name,
              subtitle: this.content.subtitle,
              description: this.content.description,
              picture: this.content.picture,
            },
            stampIds: stampIds,
          };
          await client.oldGroupAdd(params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "정상적으로 등록되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    },
    removeFile() {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              if (this.selectedImg) {
                this.selectedImg = null;
              }
              this.imgName = "";
            }
          },
        }
      );
    },
    previewFile(e) {
      let refSelectedImg = this.$refs.refSelectedImg;
      if (0 < this.$refs.refSelectedImg.files.length) {
        this.selectedImg = refSelectedImg.files[0];
        let fileExt = this.selectedImg.name.substring(
          this.selectedImg.name.lastIndexOf(".") + 1
        );
        fileExt = fileExt.toLowerCase();
        if (
          ["jpeg", "jpg", "png", "gif", "bmp"].includes(fileExt) &&
          this.selectedImg.size <= 1048576
        ) {
          const file = e.target.files[0];
          this.imgName = file.name;
        } else {
          alert("파일을 다시 선택해 주세요.");
          this.selectedImg = null;
        }
      } else {
        this.selectedImg = null;
      }
    },
    callStamp() {
      this.$modal.show(
        StampCall,
        {
          type: "general",
          id: this.id,
          stampIds: this.stamp.ids,
        },
        {
          width: 804,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              this.stamp.ids = response.params.stampIds;
              this.fetchStampIds();
            }
          },
        }
      );
    },
  },
};
</script>
